var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_purchase_order_detail_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "FormReport",
          attrs: {
            model: _vm.formModel,
            rules: _vm.formRules,
            "label-align": "left",
            "label-col": { span: 8 },
            "wrapper-col": { span: 12 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm("find")
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" }
                    },
                    [
                      _c("branch-select", {
                        attrs: { value: _vm.formModel.branchId },
                        on: {
                          change: function(e) {
                            return _vm.onChange(e, "branchId")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_purchase_order_type"),
                        prop: "poType"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": _vm.useLocalFilter,
                            loading: _vm.loading.poType,
                            "option-filter-prop": "children"
                          },
                          model: {
                            value: _vm.formModel.poType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "poType", $$v)
                            },
                            expression: "formModel.poType"
                          }
                        },
                        _vm._l(_vm.optPoTypes, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.label))]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier_name"),
                        prop: "supplierId"
                      }
                    },
                    [
                      _c("contact-select", {
                        attrs: {
                          value: _vm.formModel.supplierId,
                          query: "supplier~true_AND_active~true"
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChange(e, "supplierId")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_po_number"), prop: "poId" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.poNumber
                          },
                          on: { search: _vm.onSearchPo },
                          model: {
                            value: _vm.formModel.poId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "poId", $$v)
                            },
                            expression: "formModel.poId"
                          }
                        },
                        _vm._l(_vm.optPoNumbers, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_number"),
                        prop: "partId"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.part,
                            "dropdown-match-select-width": false
                          },
                          on: {
                            search: _vm.searchPart,
                            change: _vm.onChangePart
                          },
                          model: {
                            value: _vm.formModel.partId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "partId", $$v)
                            },
                            expression: "formModel.partId"
                          }
                        },
                        _vm._l(_vm.optParts, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_number"),
                        prop: "invoiceApId"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.invoice
                          },
                          on: {
                            search: function(e) {
                              return _vm.onSearchInvoice(e, "invoiceAP")
                            }
                          },
                          model: {
                            value: _vm.formModel.invoiceApId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "invoiceApId", $$v)
                            },
                            expression: "formModel.invoiceApId"
                          }
                        },
                        _vm._l(_vm.optInvoiceNumbers, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_supplier_number"),
                        prop: "invoiceSupplierNo"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.invoice
                          },
                          on: {
                            search: function(e) {
                              return _vm.onSearchInvoice(e, "invoiceSupplier")
                            }
                          },
                          model: {
                            value: _vm.formModel.invoiceSupplierNo,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "invoiceSupplierNo", $$v)
                            },
                            expression: "formModel.invoiceSupplierNo"
                          }
                        },
                        _vm._l(_vm.optInvoiceSupplierNumbers, function(item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.key,
                              attrs: {
                                value: item.meta
                                  ? item.meta.invoiceSupplierNo
                                  : item.value
                              }
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_purchase_order_date"),
                        prop: "poDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.useDisabledFromTomorrow
                        },
                        model: {
                          value: _vm.formModel.poDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "poDate", $$v)
                          },
                          expression: "formModel.poDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_invoice_date"),
                        prop: "invoiceDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.useDisabledFromTomorrow
                        },
                        model: {
                          value: _vm.formModel.invoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "invoiceDate", $$v)
                          },
                          expression: "formModel.invoiceDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "html-type": "button" },
                          on: { click: _vm.reset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            type: "primary",
                            loading: _vm.loading.find
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  "data-source": _vm.dataSource,
                  columns: _vm.columns,
                  pagination: _vm.pagination,
                  loading: _vm.loading.find,
                  size: "small",
                  scroll: { x: 1200 }
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "nullable",
                    fn: function(text) {
                      return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                    }
                  },
                  {
                    key: "number",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("qty")(text)))
                      ])
                    }
                  },
                  {
                    key: "currency",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("currency")(text)))
                      ])
                    }
                  },
                  {
                    key: "date",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("date")(text)))
                      ])
                    }
                  },
                  {
                    key: "expandedRowRender",
                    fn: function(record) {
                      return [
                        _c(
                          "a-descriptions",
                          {
                            attrs: {
                              title: _vm.$t("lbl_details"),
                              bordered: "",
                              layout: "vertical",
                              column: 4,
                              size: "small"
                            }
                          },
                          [
                            _c(
                              "a-descriptions-item",
                              { attrs: { label: _vm.$t("lbl_description") } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(record.poDescription || "-") +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("a-divider", { attrs: { orientation: "left" } }, [
                          _vm._v(_vm._s(_vm.$t("lbl_purchase_order")))
                        ]),
                        _c("a-table", {
                          attrs: {
                            "data-source": record.poDetail,
                            columns: _vm.detailColumn.poDetail,
                            scroll: { x: 1200 },
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-key": function(row, i) {
                              return i
                            },
                            size: "small"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "nullable",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(text || "-"))
                                  ])
                                }
                              },
                              {
                                key: "number",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("qty")(text)))
                                  ])
                                }
                              },
                              {
                                key: "currency",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("currency")(text)))
                                  ])
                                }
                              },
                              {
                                key: "date",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("date")(text)))
                                  ])
                                }
                              },
                              {
                                key: "expandedRowRender",
                                fn: function(record2) {
                                  return [
                                    _c(
                                      "a-descriptions",
                                      {
                                        attrs: {
                                          title: _vm.$t("lbl_details"),
                                          bordered: "",
                                          layout: "vertical",
                                          column: 4,
                                          size: "small"
                                        }
                                      },
                                      [
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_capacity")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record2.capacity || "-"
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_serial_number")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record2.serialNumber || "-"
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: { label: _vm.$t("lbl_type") }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(record2.type || "-") +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: { label: _vm.$t("lbl_uom") }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(record2.uomName || "-") +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_currency")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record2.currencyCode || "-"
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_currency_rate")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    record2.currencyRate
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "a-descriptions-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("lbl_description")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record2.poLineDescription ||
                                                    "-"
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("a-divider", { attrs: { orientation: "left" } }, [
                          _vm._v(_vm._s(_vm.$t("lbl_good_receipt")))
                        ]),
                        _c("a-table", {
                          attrs: {
                            "data-source": record.grDetail,
                            columns: _vm.detailColumn.grDetail,
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-key": function(row, i) {
                              return i
                            },
                            scroll: { x: 1200 },
                            size: "small"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "nullable",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(text || "-"))
                                  ])
                                }
                              },
                              {
                                key: "number",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("qty")(text)))
                                  ])
                                }
                              },
                              {
                                key: "currency",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("currency")(text)))
                                  ])
                                }
                              },
                              {
                                key: "date",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("date")(text)))
                                  ])
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("a-divider", { attrs: { orientation: "left" } }, [
                          _vm._v(_vm._s(_vm.$t("lbl_invoice")))
                        ]),
                        _c("a-table", {
                          attrs: {
                            "data-source": record.invoiceDetail,
                            columns: _vm.detailColumn.invoiceDetail,
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-key": function(row, i) {
                              return i
                            },
                            scroll: { x: 1200 },
                            size: "small"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "nullable",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(text || "-"))
                                  ])
                                }
                              },
                              {
                                key: "number",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("qty")(text)))
                                  ])
                                }
                              },
                              {
                                key: "currency",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("currency")(text)))
                                  ])
                                }
                              },
                              {
                                key: "date",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("date")(text)))
                                  ])
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("a-divider", { attrs: { orientation: "left" } }, [
                          _vm._v(_vm._s(_vm.$t("lbl_returns")))
                        ]),
                        _c("a-table", {
                          attrs: {
                            "data-source": record.returnDetail,
                            columns: _vm.detailColumn.returnDetail,
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-key": function(row, i) {
                              return i
                            },
                            scroll: { x: 1200 },
                            size: "small"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "nullable",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(text || "-"))
                                  ])
                                }
                              },
                              {
                                key: "number",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("qty")(text)))
                                  ])
                                }
                              },
                              {
                                key: "currency",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("currency")(text)))
                                  ])
                                }
                              },
                              {
                                key: "date",
                                fn: function(text) {
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(_vm._f("date")(text)))
                                  ])
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.download },
                      on: {
                        click: function($event) {
                          return _vm.validateForm("download")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }